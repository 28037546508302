import {DestroyRef, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TranslateService} from '@ngx-translate/core';
import {Action, NgxsOnInit, State, StateContext, Store} from '@ngxs/store';
import {CookieConstants} from '@shared/core/constants/cookie.const';
import {AuthenticationService} from '@shared/core/services/authentication.service';
import {CookieService} from 'ngx-cookie-service';
import {filter, take, tap} from 'rxjs';
import {Language} from '../../../../../projects/admin-query/src/app/core/api/generated/msa-admin-query';
import {generateTraceId} from '../../utils/tracking.utils';
import {ChangeLanguage, OnAuthenticated} from '../actions/app.state.action';
import {AppStateModel} from '../models/app.state.model';

const DFLT_APP_STATE = {
  language: Language.De,
  traceId: generateTraceId()
};

@State<AppStateModel>({
  name: 'app',
  defaults: {
    ...DFLT_APP_STATE
  }
})
@Injectable()
export class AppState implements NgxsOnInit {
  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private authenticationService: AuthenticationService,
    private store: Store,
    private destroyRef: DestroyRef
  ) {}

  ngxsOnInit(ctx: StateContext<AppStateModel>) {
    const userLanguagePreference = this.cookieService.get(CookieConstants.LANGUAGE);
    if (userLanguagePreference) {
      this.changeLanguage(ctx, new ChangeLanguage(userLanguagePreference));
    }

    // listen if the user is already authenticated
    this.authenticationService
      .isAuthenticated()
      .pipe(
        filter(Boolean),
        take(1),
        tap(() => {
          this.store.dispatch(new OnAuthenticated());
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  @Action(ChangeLanguage)
  changeLanguage(ctx: StateContext<AppStateModel>, {language}: ChangeLanguage) {
    const sanitizedLanguage = language.toLowerCase();

    this.translate.use(sanitizedLanguage);
    // do not change the attributes of this cookie, there is dependency im nginx.conf to it, see nginx.conf and also DMLRINF-1133
    this.cookieService.set(CookieConstants.LANGUAGE, sanitizedLanguage, undefined, '/');

    let updatedLanguage;
    switch (sanitizedLanguage) {
      case 'de':
        updatedLanguage = Language.De;
        break;
      case 'fr':
        updatedLanguage = Language.Fr;
        break;
      case 'it':
        updatedLanguage = Language.It;
        break;
      default:
        throw new Error(`Language ${sanitizedLanguage} unknown!`);
    }

    ctx.patchState({language: updatedLanguage});
  }
}
