import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { MsaAdminQueryConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { CodesRestService } from './api/codes.service';
import { DocumentsRestService } from './api/documents.service';
import { DutiesRestService } from './api/duties.service';
import { LeaveRequestRestService } from './api/leaveRequest.service';
import { RequestRestService } from './api/request.service';
import { ShiftRequestRestService } from './api/shiftRequest.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    CodesRestService,
    DocumentsRestService,
    DutiesRestService,
    LeaveRequestRestService,
    RequestRestService,
    ShiftRequestRestService ]
})
export class MsaAdminQueryApiModule {
    public static forRoot(configurationFactory: () => MsaAdminQueryConfiguration): ModuleWithProviders<MsaAdminQueryApiModule> {
        return {
            ngModule: MsaAdminQueryApiModule,
            providers: [ { provide: MsaAdminQueryConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: MsaAdminQueryApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('MsaAdminQueryApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
