/**
 * Admin Query Service API
 * REST API for Admin Query Service
 *
 * The version of the OpenAPI document: 2.0
 * Contact: dimilar-devops@bit.admin.ch 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum Action {
    CreateLeaveRequest = 'CREATE_LEAVE_REQUEST',
    CreateShiftRequest = 'CREATE_SHIFT_REQUEST',
    DeleteRequest = 'DELETE_REQUEST',
    EditRequest = 'EDIT_REQUEST',
    OpenReconsiderationRequest = 'OPEN_RECONSIDERATION_REQUEST',
    SubmitRequest = 'SUBMIT_REQUEST',
    ViewOverview = 'VIEW_OVERVIEW',
    WithdrawRequest = 'WITHDRAW_REQUEST'
}

