/**
 * Admin Query Service API
 * REST API for Admin Query Service
 *
 * The version of the OpenAPI document: 2.0
 * Contact: dimilar-devops@bit.admin.ch 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DocumentDto { 
    id: string;
    name: string;
    type: DocumentDtoTypeEnum;
}
export enum DocumentDtoTypeEnum {
    Response = 'RESPONSE',
    Other = 'OTHER',
    Medical = 'MEDICAL'
};



